import React, { Component } from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { Redirect, Route, Switch } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import "assets/vendors/style";
import defaultTheme from "./themes/defaultTheme";
import AppLocale from "../lngProvider";

import MainApp from "app/index";
import SignIn from "./SignIn";
import ForgotPassword from "./ForgotPassword";
import { setInitUrl } from "../actions/Auth";
import RTL from "util/RTL";
import asyncComponent from "util/asyncComponent";
import { getFirestore } from "redux-firestore";
// import Stripe from 'stripe';
// Stripe.setPublishableKey("pk_test_4tiTB7vyDPySoraxaY71rqyf");

// import {loadStripe} from '@stripe/stripe-js';
// const stripe = await loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx');

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

class App extends Component {
  componentDidMount() {
    this.getFirestoreUser();
  }

  componentDidUpdate(prevProp) {
    if (prevProp.authUser !== this.props.authUser) {
      this.getFirestoreUser();
    }
  }

  getFirestoreUser = () => {
    const { authUser } = this.props;
    if (authUser !== null) {
      const firestore = getFirestore();
      firestore.setListeners([
        {
          collection: "users",
          doc: authUser,
        },
      ]);
    }
  };

  componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === "") {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
  }

  render() {
    const {
      match,
      location,
      locale,
      authUser,
      initURL,
      isDirectionRTL,
      users,
    } = this.props;
    if (location.pathname === "/") {
      if (authUser === null) {
        return <Redirect to={"/signin"} />;
      } else if (initURL === "" || initURL === "/" || initURL === "/signin") {
        if (users !== undefined && users.length > 0) {
          if (users[0].role === "Creator") {
            if (
              users[0].firstTime == undefined ||
              users[0].firstTime === true
            ) {
              const firestore = getFirestore();
              firestore.update(
                {
                  collection: "users",
                  doc: users[0]["id"],
                },
                {
                  firstTime: false,
                }
              );
              return <Redirect to={"/app/products"} />;
            } else {
              if (!users[0].isMaster) {
                return <Redirect to={"/app/products"} />;
              } else {
                return <Redirect to={"/app/settings"} />;
              }
            }
          } else if (users[0].role === "Super Admin") {
            return <Redirect to={"/app/admin"} />;
          } else {
            // console.log('ffff >>>', users[0].role);
            return <Redirect to={"/app/not-allowed"} />;
          }
        }
      } else {
        return <Redirect to={initURL} />;
      }
    }
    const applyTheme = createMuiTheme(defaultTheme);

    if (isDirectionRTL) {
      applyTheme.direction = "rtl";
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
      applyTheme.direction = "ltr";
    }

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <RTL>
              <div className="app-main">
                {(users !== undefined && users.length > 0) || !authUser ? (
                  <Switch>
                    <RestrictedRoute
                      path={`${match.url}app`}
                      authUser={authUser}
                      component={MainApp}
                    />
                    <Route path="/signin" component={SignIn} />
                    <Route path="/forgot-password" component={ForgotPassword} />
                    {/* <Route path='/signup' component={SignUp}/> */}
                    <Route
                      component={asyncComponent(() =>
                        import("components/Error404")
                      )}
                    />
                  </Switch>
                ) : (
                  <div className="centered-appmain">
                    <CircularProgress />
                  </div>
                )}
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth, firestore }) => {
  const { sideNavColor, locale, isDirectionRTL } = settings;
  const { authUser, initURL } = auth;
  const { users } = firestore.ordered;
  return { sideNavColor, locale, isDirectionRTL, authUser, initURL, users };
};

export default connect(mapStateToProps, { setInitUrl })(App);
