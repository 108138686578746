import React from 'react';
import {ConnectedRouter} from 'connected-react-router'
import {Provider} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import {ReactReduxFirebaseProvider} from 'react-redux-firebase'
import {createFirestoreInstance } from 'redux-firestore' 
import configureStore, {history} from './store';
import './firebase/firebase';
import App from './containers/App';
import firebase from 'firebase';

export const store = configureStore();
// react-redux-firebase config
const rrfConfig = {
  userProfile: "users",
  attachAuthIsReady: true,
  useFirestoreForProfile: true,
  logErrors: true,
  enableLogging: true
}

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
}

const MainApp = () =>
  <ReactReduxFirebaseProvider {...rrfProps}>
    <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/" component={App}/>
          </Switch>
        </ConnectedRouter>
    </Provider>
  </ReactReduxFirebaseProvider>;


export default MainApp;