import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "components/Header/index";
import Sidebar from "containers/SideNav/index";
import Footer from "components/Footer";
import Tour from "../components/Tour/index";
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "constants/ActionTypes";
import { isIOS, isMobile } from "react-device-detect";
import asyncComponent from "../util/asyncComponent";
import TopNav from "components/TopNav";

class App extends React.Component {
  render() {
    const {
      match,
      drawerType,
      navigationStyle,
      horizontalNavPosition,
      users,
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "fixed-drawer"
      : drawerType.includes(COLLAPSED_DRAWER)
      ? "collapsible-drawer"
      : "mini-drawer";

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        <Tour />

        <Sidebar role={users[0].role} />
        <div className="app-main-container">
          <div
            className={`app-header ${
              navigationStyle === HORIZONTAL_NAVIGATION
                ? "app-header-horizontal"
                : ""
            }`}
          >
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === ABOVE_THE_HEADER && (
                <TopNav styleName="app-top-header" />
              )}
            <Header />
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              {users[0].role === "Super Admin" && (
                <Switch>
                  <Route
                    path={`${match.url}/admin`}
                    component={asyncComponent(() => import("./routes/Admin"))}
                  />
                  <Route
                    path={`${match.url}/coupons`}
                    component={asyncComponent(() => import("./routes/Coupons"))}
                  />
                  <Route
                    path={`${match.url}/customers`}
                    component={asyncComponent(() =>
                      import("./routes/Customers")
                    )}
                  />
                  <Route
                    path={`${match.url}/payouts`}
                    component={asyncComponent(() => import("./routes/Payouts"))}
                  />
                  <Route
                    path={`${match.url}/products`}
                    component={asyncComponent(() =>
                      import("./routes/Products")
                    )}
                  />
                  <Route
                    path={`${match.url}/orders`}
                    component={asyncComponent(() => import("./routes/Orders"))}
                  />
                  <Route
                    path={`${match.url}/reports`}
                    component={asyncComponent(() => import("./routes/Reports"))}
                  />
                  <Route
                    path={`${match.url}/product/new`}
                    component={asyncComponent(() =>
                      import("./routes/NewProduct")
                    )}
                  />
                  <Route
                    path={`${match.url}/product/edit`}
                    component={asyncComponent(() =>
                      import("./routes/NewProduct")
                    )}
                  />
                  <Route
                    path={`${match.url}/settings`}
                    component={asyncComponent(() =>
                      import("./routes/Settings")
                    )}
                  />
                  <Route
                    path={`${match.url}/scripts`}
                    component={asyncComponent(() => import("./routes/Scripts"))}
                  />
                  <Route
                    component={asyncComponent(() =>
                      import("components/Error404")
                    )}
                  />
                </Switch>
              )}
              {users[0].role === "Creator" && (
                <Switch>
                  <Route
                    path={`${match.url}/products`}
                    component={asyncComponent(() =>
                      import("./routes/Products")
                    )}
                  />
                  <Route
                    path={`${match.url}/orders`}
                    component={asyncComponent(() => import("./routes/Orders"))}
                  />
                  <Route
                    path={`${match.url}/reports`}
                    component={asyncComponent(() => import("./routes/Reports"))}
                  />
                  <Route
                    path={`${match.url}/product/new`}
                    component={asyncComponent(() =>
                      import("./routes/NewProduct")
                    )}
                  />
                  <Route
                    path={`${match.url}/product/edit`}
                    component={asyncComponent(() =>
                      import("./routes/NewProduct")
                    )}
                  />
                  <Route
                    path={`${match.url}/settings`}
                    component={asyncComponent(() =>
                      import("./routes/Settings")
                    )}
                  />
                  <Route
                    component={asyncComponent(() =>
                      import("components/Error404")
                    )}
                  />
                </Switch>
              )}
              {users[0].role === "User" && (
                <Switch>
                  <Route
                    path={`${match.url}/not-allowed`}
                    component={asyncComponent(() =>
                      import("../containers/NotAllowed")
                    )}
                  />
                  <Route
                    component={asyncComponent(() =>
                      import("components/Error404")
                    )}
                  />
                </Switch>
              )}
            </div>
            <Footer />
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings, firestore }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  const { ordered } = firestore;
  const users = ordered.users !== undefined ? ordered.users : [];
  return { drawerType, navigationStyle, horizontalNavPosition, users };
};
export default withRouter(connect(mapStateToProps)(App));
