import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { getFirestore } from "redux-firestore";

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`; // get current path

    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function(event) {
        const parentLiEle = that.closest(this, "li");
        if (menuLi[i].classList.contains("menu") && parentLiEle !== null) {
          event.stopPropagation();

          if (menuLi[i].classList.contains("open")) {
            menuLi[i].classList.remove("open", "active");
          } else {
            menuLi[i].classList.add("open", "active");
          }
        } else {
          for (let j = 0; j < menuLi.length; j++) {
            const parentLi = that.closest(this, "li");
            if (
              menuLi[j] !== this &&
              (parentLi === null || !parentLi.classList.contains("open"))
            ) {
              menuLi[j].classList.remove("open");
            } else {
              if (menuLi[j].classList.contains("open")) {
                menuLi[j].classList.remove("open");
              } else {
                menuLi[j].classList.add("open");
              }
            }
          }
        }
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  componentDidUpdate(prevProps) {}

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element

    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function(fn) {
        if (typeof document.body[fn] === "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    const { location, role } = this.props;
    if (role == "Super Admin") {
      if (location.pathname === "/app/admin") {
        this.emptyOrganizerInfo();
      }
    }
  }

  emptyOrganizerInfo = () => {
    const { creator, role } = this.props;
    if (creator.length > 0 && role === "Super Admin") {
      const firestore = getFirestore();
      firestore.unsetListener("creator");
      firestore.get({
        collection: "users",
        storeAs: "creator",
        doc: "asdadasdasd",
      });
    }
  };

  render() {
    const { role, advertiserInfo, users, creator } = this.props;
    return (
      <CustomScrollbars className="scrollbar">
        <ul className="nav-menu">
          {users[0].role === "Super Admin" && (
            <>
              <li className="nav-header">Admin</li>
              <li className="menu no-arrow">
                <NavLink to="/app/admin">
                  <i className="zmdi zmdi zmdi-chart zmdi-hc-fw" />
                  <span className="nav-text">admin</span>
                </NavLink>
              </li>
              <li className="menu no-arrow">
                <NavLink to="/app/coupons">
                  <i className="zmdi zmdi zmdi-card-giftcard zmdi-hc-fw" />
                  <span className="nav-text">Coupons</span>
                </NavLink>
              </li>
              {/* <li className="menu no-arrow">
                <NavLink to="/app/payouts">
                  <i className="zmdi zmdi zmdi-money zmdi-hc-fw" />
                  <span className="nav-text">Payouts Dashboard</span>
                </NavLink>
              </li> */}
              <li className="menu no-arrow">
                <NavLink to="/app/customers">
                  <i className="zmdi zmdi zmdi-account zmdi-hc-fw" />
                  <span className="nav-text">Customers</span>
                </NavLink>
              </li>
              {creator != undefined && creator.length > 0 && (
                <>
                  {creator.length > 0 && (
                    <li
                      onClick={() => {
                        this.emptyOrganizerInfo();
                        this.props.history.push(`/app/admin`);
                      }}
                      style={{ cursor: "pointer" }}
                      className="nav-header"
                    >
                      <KeyboardArrowLeft />
                      Go Back to {users[0]["creatorName"]}
                    </li>
                  )}
                  <li className="nav-header">
                    {`${
                      creator.length > 0
                        ? `${creator[0]["creatorName"]}`
                        : `Organization`
                    }`}
                  </li>
                  {creator[0]["isMaster"] == undefined &&
                    !creator[0]["isMaster"] && (
                      <>
                        <li className="menu no-arrow">
                          <NavLink
                            to={`/app/products?creator=${creator[0]["id"]}`}
                          >
                            <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
                            <span className="nav-text">Products</span>
                          </NavLink>
                        </li>
                      </>
                    )}
                  <li className="menu no-arrow">
                    <NavLink to={`/app/orders?creator=${creator[0]["id"]}`}>
                      <i className="zmdi zmdi-shopping-cart zmdi-hc-fw" />
                      <span className="nav-text">Orders</span>
                    </NavLink>
                  </li>
                  {/* <li className="menu no-arrow">
                    <NavLink to={`/app/reports?creator=${creator[0]['id']}`}>
                      <i className="zmdi zmdi-chart zmdi-hc-fw"/>
                      <span className="nav-text">Reports</span>
                    </NavLink>
                  </li> */}
                  <li className="menu no-arrow">
                    <NavLink to={`/app/settings?creator=${creator[0]["id"]}`}>
                      <i className="zmdi zmdi-settings zmdi-hc-fw" />
                      <span className="nav-text">Settings</span>
                    </NavLink>
                  </li>
                </>
              )}
            </>
          )}
          {users[0].role === "Creator" && (
            <>
              <li className="nav-header">Organization</li>

              {creator != undefined &&
                creator.length > 0 &&
                creator[0]["isMaster"] == undefined &&
                !creator[0]["isMaster"] && (
                  <li className="menu no-arrow">
                    <NavLink to={`/app/products`}>
                      <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
                      <span className="nav-text">Products</span>
                    </NavLink>
                  </li>
                )}
              <li className="menu no-arrow">
                <NavLink to={`/app/orders`}>
                  <i className="zmdi zmdi-shopping-cart zmdi-hc-fw" />
                  <span className="nav-text">Orders</span>
                </NavLink>
              </li>
              {/* <li className="menu no-arrow">
                <NavLink to={`/app/reports`}>
                  <i className="zmdi zmdi-chart zmdi-hc-fw"/>
                  <span className="nav-text">Reports</span>
                </NavLink>
              </li> */}
              <li className="menu no-arrow">
                <NavLink to={`/app/settings`}>
                  <i className="zmdi zmdi-settings zmdi-hc-fw" />
                  <span className="nav-text">Settings</span>
                </NavLink>
              </li>
            </>
          )}
          {/* <li className="nav-header">
          Admin
        </li>
        <li className="menu no-arrow">
          <NavLink to="/app/campaigns">
            <i className="zmdi zmdi zmdi-chart zmdi-hc-fw"/>
            <span className="nav-text">Campaign</span>
          </NavLink>
        </li>
        <li className="menu no-arrow">
          <NavLink to="/app/settings">
            <i className="zmdi zmdi-settings zmdi-hc-fw"/>
            <span className="nav-text">Settings</span>
          </NavLink>
        </li> */}
        </ul>
      </CustomScrollbars>
    );
  }
}

export default withRouter(SidenavContent);
