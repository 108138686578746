import React from 'react';
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
// import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link} from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import {
  hideMessage,
  showAuthLoader,
  hideAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignUp,
  userTwitterSignIn
} from 'actions/Auth';
import { getFirebase } from 'react-redux-firebase';

class ForgotPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      isEmailSend: false
    }
  }

  componentDidMount(){
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 3000);
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
  }

  doForgotPassword = async () => {
    const {
      email
    } = this.state;
    if(email === ''){
      NotificationManager.error('Email is required');
      return;
    }
    this.props.showAuthLoader();
    const firebase = getFirebase();
    await firebase.auth().sendPasswordResetEmail(email).then(email => {
        NotificationManager.success("Reset Email send successfully");
        this.setState({ isEmailSend: true });
    }).catch(error => {
        NotificationManager.error(error.message);
    });
    this.props.hideAuthLoader();
    // this.props.userSignUp({email, password, phone, firstname, lastname, businessName});
  }

  render() {
    const {
      email,
      isEmailSend
    } = this.state;
    const {showMessage, loader, alertMessage} = this.props;
    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="Jambo">
              <img className="md-lg" src={require("assets/images/kosherflix_white.png")} alt="jambo" title="jambo"/>
            </Link>
          </div>
        
          <div className="app-login-content">
            <div className="app-login-header">
              <h1>Forgot Password ?</h1>
            </div>

            {/* <div className="mb-4">
              <h2><IntlMessages id="appModule.createAccount"/></h2>
            </div> */}

            {
                isEmailSend ? (
                    <p>Check your email to reset password</p>
                ) : (
                <div className="app-login-form">
                    <form method="post" action="/">
                    
                        <TextField
                            type="email"
                            onChange={(event) => this.setState({email: event.target.value})}
                            label={<IntlMessages id="appModule.email"/>}
                            fullWidth
                            defaultValue={email}
                            margin="normal"
                            className="mt-0 mb-2"
                        />

                        <div className="mb-3 mt-4 d-flex align-items-center justify-content-between">
                            <Button variant="contained" onClick={() => this.doForgotPassword()} color="primary">
                                Submit
                            </Button>
                            <Link to="/signin">
                                Go Back to Login
                            </Link>
                        </div>
                    </form>
                </div>
                )
            }
          </div>

        </div>

        {
          loader &&
          <div className="loader-view">
            <CircularProgress/>
          </div>
        }
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer/>
      </div>
    )
  }
}

const mapStateToProps = ({auth}) => {
  const {loader, alertMessage, showMessage, authUser} = auth;
  return {loader, alertMessage, showMessage, authUser}
};

export default connect(mapStateToProps, {
  userSignUp,
  hideMessage,
  showAuthLoader,
  hideAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  userGithubSignIn,
  userTwitterSignIn
})(ForgotPassword);
